import { mapState } from 'vuex';
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      questionList: []
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.$http.get(this.$api.question_list, {
        params: {
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        console.log(res);
        if (res.code == 0) {
          if (res.data) {
            this.questionList = res.data;
          }
        }
      });
    }
  }
};