var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/cjwt.jpg")
    }
  })]), _c("div", {
    staticClass: "questionBox"
  }, _vm._l(_vm.questionList, function (items, index) {
    return _c("div", {
      staticClass: "questionBlock"
    }, [_c("div", {
      staticClass: "question-hd"
    }, [_vm._v(_vm._s(items.questionType))]), _c("el-collapse", {
      attrs: {
        accordion: ""
      }
    }, _vm._l(items.detailList, function (item, key) {
      return _c("el-collapse-item", {
        attrs: {
          title: item.questTitle
        }
      }, [_c("div", {
        domProps: {
          innerHTML: _vm._s(item.questContext)
        }
      })]);
    }), 1)], 1);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };